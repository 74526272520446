









import { Vue, Component, Ref } from 'vue-property-decorator'
import PaymentsTable from '@/components/payments/PaymentsTable'
import { studentStore } from '@/store'

@Component({ components: { PaymentsTable } })
export default class StudentPayments extends Vue {
  @Ref() readonly paymentsTable!: { refresh: () => Promise<void> }

  get studentId() {
    return studentStore.student!.id
  }

  refresh() {
    this.paymentsTable.refresh()
  }
}
