<template>
  <div>
    <InfoBlock />
    <AddressBlock />
    <OrdersCard @update="$refs.studentPayments.refresh()" />
    <StudentPaymentsCard ref="studentPayments" />
  </div>
</template>

<script>
import InfoBlock from '@/components/student/Info.vue'
import AddressBlock from '@/components/student/Address.vue'
import StudentPaymentsCard from '@/components/student/StudentPaymentsCard.vue'
import OrdersCard from '@/components/student/OrdersCard.tsx'

export default {
  components: {
    InfoBlock,
    AddressBlock,
    StudentPaymentsCard,
    OrdersCard,
  },
}
</script>
