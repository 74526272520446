















































































































































































































































import { Vue, Component } from 'vue-property-decorator'
import { IStudent } from '@/store/studentStore'
import { studentStore } from '@/store'
import { showError } from '@/helpers/notifications'
import ResetPasswordModal from '@/components/modals/StudentResetPasswordModal.vue'

const WayupPhoneInput = require('wayup-phone-input')
import 'wayup-phone-input/dist/wayup-phone-input.css'
import { userStore } from '@/store'
import axios from '@/libs/axios'
import { AxiosResponse } from 'axios'

@Component({
  components: {
    WayupPhoneInput,
    ResetPasswordModal,
  },
})
export default class StudentInfo extends Vue {
  $refs!: {
    phoneInput: HTMLElement & {
      validate: () => boolean
      checkValue: (value: string) => void
    }
    resetPasswordModal: HTMLElement & {
      show: (data: { id: number; name: string }) => void
    }
  }

  state: 'view' | 'edit' = 'view'
  firstName = ''
  lastName = ''
  patronymic = ''
  email = ''
  birthday = ''
  phone = ''
  telegram = ''
  whatsapp = ''
  viber = ''
  instagram = ''
  facebook = ''
  vk = ''
  tiktok = ''
  superStudent = false

  firstNameValid = true
  lastNameValid = true
  emailValid = true
  phoneValid = true

  points = 0

  pending = false

  get student() {
    return studentStore.student!
  }

  get fullName() {
    return `${this.student.lastName ? `${this.student.lastName} ` : ''}${
      this.student.firstName ? this.student.firstName : ''
    }${this.student.patronymic ? ` ${this.student.patronymic}` : ''}`
  }

  get loginButtonVisible() {
    return userStore.user?.group.permissions['admin.account.sessions']
  }

  mounted() {
    this.fillEditData()
    this.$refs.phoneInput.checkValue(this.phone)
  }

  onEditClick() {
    this.state = 'edit'
    this.fillEditData()
  }

  onCancelClick() {
    this.superStudent = this.student.superStudent || false
    this.state = 'view'
  }

  onSaveClick() {
    this.state = 'edit'
    this.saveData()
  }

  async saveData() {
    if (!this.validate()) {
      return
    }

    this.pending = true

    if (
      await studentStore.saveStudentData({
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        middleName: this.patronymic,
        phone: this.phone,
        dob: this.birthday,
        telegram: this.telegram,
        whatsApp: this.whatsapp,
        viber: this.viber,
        instagram: this.instagram,
        faceBook: this.facebook,
        vk: this.vk,
        tikTok: this.tiktok,
        superStudent: this.superStudent,
      })
    ) {
      this.state = 'view'
    }

    this.pending = false
  }

  validate() {
    let isValid = true

    if (!this.firstName) {
      this.firstNameValid = false
      showError('Введите имя')
      isValid = false
    }

    if (!this.lastName) {
      showError('Введите фамилию')
      isValid = false
    }

    if (!this.phone) {
      showError('Введите телефон')
      isValid = false
    }

    if (!this.$refs.phoneInput.validate()) {
      showError('Некорректный номер телефона')
      isValid = false
    }

    if (!this.email) {
      showError('Введите e-mail')
      isValid = false
    }

    const re = /\S+@\S+\.\S+/
    if (!re.test(this.email)) {
      showError('Введен некорректный e-mail')
      isValid = false
    }

    return isValid
  }

  fillEditData() {
    this.firstName = this.student.firstName
    this.lastName = this.student.lastName || ''
    this.patronymic = this.student.patronymic || ''
    this.email = this.student.email
    this.birthday = this.student.dob || ''
    this.phone = this.student.phone

    this.points = this.student.points
    this.superStudent = this.student.superStudent || false

    this.telegram = this.student.social.telegram || ''
    this.whatsapp = this.student.social.whatsApp || ''
    this.viber = this.student.social.viber || ''
    this.instagram = this.student.social.instagram || ''
    this.facebook = this.student.social.faceBook || ''
    this.vk = this.student.social.vk || ''
    this.tiktok = this.student.social.tikTok || ''
  }

  resetPassword() {
    this.$refs.resetPasswordModal.show({
      id: this.student.id,
      name: `${this.student.firstName} ${this.student.lastName}`,
    })
  }

  async loginStudent() {
    try {
      const response: AxiosResponse<{
        sessionToken: string
      }> = await axios.post('v1/auth/session/token', {
        userId: this.student.id,
        userType: 'student',
      })

      const url = `${process.env.VUE_APP_LPC_URL}/admin-login?token=${response.data.sessionToken}`

      window.open(url, '_blank')?.focus()
    } catch (error) {
      console.log(error)
      showError('При получении авторизационного токена произошла ошибка')
    }
  }
}
