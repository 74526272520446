import { Vue, Component, Ref } from 'vue-property-decorator'
import { BButton, BCard } from '@/components/bootstrap'
import { showError } from '@/helpers/notifications'
import axios from '@/libs/axios'
import { AxiosResponse } from 'axios'
import OrdersTable from '@/components/orders/OrdersTable'
import { ICourse, IMentor, IStream } from '@/views/Orders'
import { studentStore } from '@/store'

const AddCourseModal = require('@/components/students/AddCourseModal.vue')
  .default

@Component
export default class StudentOrdersCard extends Vue {
  @Ref() readonly addCourseModal!: { show: (studentId: number) => void }
  @Ref() readonly ordersTable!: OrdersTable

  courses: ICourse[] = []
  mentors: IMentor[] = []
  streams: IStream[] = []

  get student() {
    return studentStore.student!
  }

  async mounted() {
    await this.fetchCourses()
    await this.fetchMentors()
  }

  async fetchCourses() {
    try {
      const response: AxiosResponse<{
        courses: Array<ICourse & { streams: Omit<IStream, 'courseId'>[] }>
      }> = await axios.get('v1/courses-with-streams')

      this.courses = response.data.courses.map(course => {
        this.streams = this.streams.concat(
          course.streams.map(stream => ({
            ...stream,
            courseId: course.id,
          })),
        )

        const { streams, ...courseWithoutStreams } = course
        return courseWithoutStreams
      })
    } catch (error) {
      console.error(error)
      showError('При загрузке курсов произошла ошибка')
    }
  }

  async fetchMentors() {
    try {
      const response: AxiosResponse<{ mentors: IMentor[] }> = await axios.get(
        'v1/mentors',
      )
      this.mentors = response.data.mentors
    } catch (error) {
      console.error(error)
      showError('При загрузке менторов произошла ошибка')
    }
  }

  protected render() {
    return (
      <BCard no-body>
        <b-card-body>
          <b-card-title class="d-flex align-items-center justify-content-between mb-0">
            Курсы
            <BButton
              variant="outline-primary"
              onClick={() => this.addCourseModal.show(this.student.id)}
            >
              Добавить курс
            </BButton>
          </b-card-title>
        </b-card-body>

        <OrdersTable
          ref="ordersTable"
          streams={this.streams}
          mentors={this.mentors}
          courses={this.courses}
          studentId={this.student.id}
          onPaymentAdded={() => this.$emit('update')}
        />

        <AddCourseModal
          ref="addCourseModal"
          onOnCourseAdded={() => {
            this.ordersTable.refreshTable()
            this.$emit('update')
          }}
        />
      </BCard>
    )
  }
}
