



















































import { BCard, BRow, BCol } from 'bootstrap-vue'
import { studentStore } from '@/store'
import { Vue, Component } from 'vue-property-decorator'

@Component({
  components: {
    BCard,
    BRow,
    BCol,
  },
})
export default class StudentAddress extends Vue {
  get student() {
    return studentStore.student
  }
}
